define(['$window','app'], function($window,app) {

  var twoItemImageTextBlock = function() {
    var self = this;
    self.app = app;

    var _config = {
      YouTubeScriptURL: 'https://www.youtube.com/iframe_api',
      playerElementSelector: '[data-js-element=videoPlayer]',
      videoIdSelector: 'data-videoURL',
      youtubeVideo: '.videoTextImageCTA_videoContainer'
    };

    var _init = function(element) {
      self.element = element;
      self.app.subscribe('yt_api_ready', self.loadVideo);
      self.youtubeClass = document.querySelector(self.config.youtubeVideo);
      self.youtubeClassCheck();

      return self;
    };

     var _youtubeClassCheck = function(element) {
        if (self.youtubeClass) {
          self.loadYouTubeAPIScript();
        }
     };



    var _loadYouTubeAPIScript = function() {

      $window.onYouTubeIframeAPIReady = function() {
        self.app.publish('yt_api_ready');
      };

      var tag = document.createElement('script');
      tag.src = self.config.YouTubeScriptURL;
      var scriptTag = document.getElementsByTagName('script')[0];
      scriptTag && scriptTag.parentNode.insertBefore(tag, scriptTag);
    };

    var _loadVideo = function() {
      var videoId = _getYouTubeID(self.element.getAttribute(_config.videoIdSelector));
      var playerElement = self.element.querySelector(self.config.playerElementSelector);

      if (videoId && playerElement) {
        self.player = new $window.YT.Player(playerElement, {
          width: '100%',
          height: '100%',
          videoId: videoId,
          playerVars: {
            rel: 0,
            modestbranding: 0,
            showinfo: 0
          }
        });
      }
    };

    var _getYouTubeID = function(url) {
      var ID = '';
      url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_-]/i);
        ID = ID[0];
      } else {
        ID = url[0];
      }
      return ID;
    };

    self.config = _config;
    self.init = _init;
    self.youtubeClassCheck = _youtubeClassCheck;
    self.loadYouTubeAPIScript =_loadYouTubeAPIScript;
    self.getYouTubeID = _getYouTubeID;
    self.loadVideo = _loadVideo;
  };

  return twoItemImageTextBlock;
});
